<script lang="ts" setup>
  import { RouteLocation } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { useDocumentVisibility } from '@vueuse/core'
  import useRouteRecord from '~/composables/RouteRecord/RouteRecord'
  import { RouteRecordId } from '~/model/RouteRecord/RouteRecord'
  import { definePageMeta } from '#imports'
  import LogoAsCode from '~/components/LogoAsCode/LogoAsCode.vue'
  import { LogoAsCodeFacet } from '~/components/LogoAsCode/LogoAsCodeFacet'
  import useTranslation from '~/composables/Translation/Translation'
  import useIsMounted from '~/composables/IsMounted/IsMounted'

  const { locale } = useI18n({ useScope: 'global' })
  const { t, tMd } = useTranslation()
  const { toRoute } = useRouteRecord()
  const isDocumentVisible = useDocumentVisibility()

  definePageMeta({
    layout: 'title-screen',
    key: (route: RouteLocation) => route.fullPath,
  })

  const isVisible = ref<boolean>(false)

  const toGame = () => {
    navigateTo(toRoute({ name: RouteRecordId.Game }))
  }

  onMounted(() => {
    watch(
      isDocumentVisible,
      (n) => {
        if (n) {
          setTimeout(() => {
            isVisible.value = true
          }, 1000)
        }
      },
      { immediate: true },
    )
  })
</script>

<template>
  <div class="c-page s-page s-page--fill s-page--intro u-typography-root">
    <Transition name="trs-slow-fade">
      <div v-if="isVisible" class="s-page__bg" />
    </Transition>
    <div class="s-page__fg c-page__logo s-page__logo">
      <LogoAsCode
        :allow-animation="true"
        :do-animation="isVisible"
        :facets="[LogoAsCodeFacet.InTitleScreen, LogoAsCodeFacet.IsNegative]"
      />
    </div>
    <div class="s-page__fg c-page__intro s-page__intro">
      <div class="s-container s-container--primary">
        <div class="c-layout__content s-container__container">
          <div class="s-grid s-grid--primary">
            <div class="c-page__content">
              <div class="c-page__claim u-markdown-root" v-html="tMd('home.claim')" />
              <div class="c-page__coming-soon-wrapper">
                <button class="btn btn--primary btn--large" @click="toGame">
                  {{ t('home.action-start.label') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/base/typography/typography' as type;

  .c-page__content {
    color: col.$variant-cyan-light;
    grid-column: 3 / 11;
    text-align: center;

    @include mq.media(('<=lg' '>md')) {
      grid-column: 2 / 12;
    }

    @include mq.media('<=md') {
      grid-column: 1 / 13;
    }
  }

  .c-page__coming-soon-wrapper {
    position: relative;
  }

  .c-page__coming-soon {
    @include type.h3;
    letter-spacing: 0.1em;
    position: absolute;
    top: 100%;
    left: 50%;
    padding: 11px 20px 9px;
    transform: rotateZ(-3deg) translateY(-50%) translateX(0) translateY(-12px);
    background-color: col.$monochrome-white;
    color: col.$monochrome-black;
    text-transform: uppercase;
    white-space: nowrap;
  }
</style>

<style lang="scss">
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;

  .c-page__claim {
    margin-bottom: 24px;
    text-shadow: 1px 1px 2px col.$monochrome-black;

    &.u-markdown-root > p {
      @include type.copy-large;
    }
  }
</style>
